$cstm-color1: #0054ca;
$cstm-color2: #e45e6b;
$cstm-color3: #c655e8;
$cstm-color4: #5573dd;
$cstm-color5: #0fbcf9;
$cstm-color6: #0fbab1;

:root {
    --custom-green: #00b255;
    --custom-red: #d32200;
}

.CandidateDetail-page {
    .tabs-wrapper {
        .nav-tabs {
            flex-wrap: nowrap;
            overflow-x: auto;
            overflow-y: hidden;
            .nav-link {
                color: var(--bs-black);
                padding: 8px 1.25rem;
                border: 0;
                border-bottom: 5px solid transparent;
                white-space: nowrap;

                @media(min-width:1200px){
                    padding: 8px 2.5rem;
                }

                &:not(.active):hover,
                &:outline {
                    border: 0 !important;
                }

                &.active {
                    border-bottom: 5px solid $cstm-color1 !important;
                    font-weight: 600;
                }
            }
        }
    }

    // For Details Tab Content
    .more-details-section {
        .nav-tabs button.nav-link {
            font-size: 14px;
            padding-left: 1.25rem;
            padding-right: 1.25rem;
        }
    }

    // For Activity Tab Content
    .candActivityStatus {
        .activity-status {
            &.completed {
                color: #00b255;
            }

            &.failed,
            &.partial-completed {
                color: var(--bs-danger);
            }
        }

        &.status-interview {
            .info-header {
                background-color: $cstm-color3;
            }
        }

        &.status-interview-scheduled {
            .info-header {
                background-color: $cstm-color4;
            }
        }

        &.status-registration {
            .info-header {
                background-color: $cstm-color5;
            }
        }

        &.status-candidate-added {
            .info-header {
                background-color: $cstm-color6;
            }
        }
    }

    // For Interview Histiry Tab Content
    .candResultStatus {
        &.result-pass {
            .info-header {
                background-color: var(--bs-warning);
            }

            .info-body {
                .result-status {
                    color: var(--bs-success);
                }
            }
        }

        &.result-fail {
            .info-header {
                background-color: $cstm-color2;
            }

            .info-body {
                .result-status {
                    color: var(--bs-danger);
                }
            }
        }
    }
}