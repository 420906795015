// Import Bootstrap's variables first
@import '../../node_modules/bootstrap/scss/functions';
@import '../../node_modules/bootstrap/scss/variables';

// Raise Requisition Page CSS
.RaiseRequisition-page {
    .right-section {
        @media (min-width: 1400px) {
            column-gap: 30px!important;
        }
    }

    .search-wrapper {
        @media (min-width: 768px) {
            min-width: 290px;
        }
    }

    @media (min-width: 1750px) {
        .right-section {
            column-gap: 90px!important;
        }
    }

    .listing-wrapper .table-row {
        display: grid;
        grid-template-columns: repeat(2, minmax(130px, 1fr));
        grid-gap: 10px;

        @media (min-width: 576px) {
            grid-template-columns: repeat(3, minmax(130px, 1fr));
        }

        @media (min-width: 768px) {
            grid-template-columns: repeat(4, minmax(130px, 1fr));
        }

        @media (min-width: 1200px) {
            grid-template-columns: repeat(6, minmax(130px, 1fr));
        }

        @media (min-width: 1900px) {
            grid-template-columns: repeat(11, minmax(130px, 1fr));
        }
    }
}

// New Requisition Page CSS
.NewRequisition-page {
    .check-wrapper {
        .form-check-label {color: $gray-600;}
        .form-check-input:checked ~ .form-check-label {
            color: $black;
        }
    }
}
.candidate-list {
    .candidateStatusCard.primary{
        border-top: 10px solid rgba(102, 16, 242, 0.75);
    }
    .candidateStatusCard.success{
        border-top: 10px solid rgba(25, 135, 84, 0.75);
    }
}