.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link{
    border: 0px;
    border-bottom: 2px solid #6610f2;
}
.nav-tabs .nav-link:hover{
    border: 0px;
    border-bottom: 2px solid #6610f2;
}
.nav-tabs .nav-link:focus{
    border: 0px;
}
.border-top{
    border-top: solid 1px #dddfe6
}
.width1000{
    width: 1000px!important;
}
.cursor-pointer{
    cursor: pointer;
}





/* 08-02-2024 */
.userName{
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
}
.userEmail{
    font-weight: normal;
    font-size: 13px;
    margin-left: 3px;
    line-height: 15px;
}
.alertMsg {
    z-index: 99999;
    top: 20px;
    left: 40%;
    width: auto;
    position: absolute;
}
.alertMsg .alert-heading h4{
    font-size: 16px;
    line-height: 20px;
}
.alertMsg .btn-close{
    display: none;
}
.noData{
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
}