$meeting-color1: #6c757d;
$cstm-color1: #14468d;
$cstm-color2: #1a5338;
$cstm-color3: #157486;
$cstm-color4: #8e7011;
$cstm-color5: #a05625;
$cstm-color6: #7d2930;

.IssuesList-page {
    overflow: hidden;

    @media(min-width: 768px) {
        // max-height: calc(100vh - 56px);
    }

    .info-cards-wrapper .info-card {
        position: relative;
        overflow: hidden;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.4);
        }
    }

    .issues-menu-section {
        li {
            a {
                font-size: 16px;
                font-weight: 500;
                white-space: nowrap;
                transition: all .5ms;

                i {
                    margin-right: 3px;
                }
            }

            a.active {
                color: var(--bs-white);
                background: linear-gradient(168.68deg, rgb(0, 82, 201) 1.12%, rgb(10, 145, 219));
            }
        }
    }

    .issues-details-section {
        .issues-list-section {
            height: 350px;
            overflow: auto;

            @media(min-width: 768px) {
                // height: calc(100vh - 325px);
                height: 100%;
            }

            @media(min-width: 1200px) {
                // height: calc(100vh - 250px);
            }

            .issue-card {
                color: var(--bs-body);
                background-color: var(--bs-white);

                &.selected {
                    color: var(--bs-white);
                    background: linear-gradient(168.68deg, rgb(0, 82, 201) 1.12%, rgb(10, 145, 219));
                }
            }
        }

        .issues-details-section {
            overflow: auto;

            @media(min-width: 768px) {
                // height: calc(100vh - 325px);
            }

            @media(min-width: 1200px) {
                // height: calc(100vh - 250px);
            }
        }
    }

    // common css for email section
    .files-wrapper {
        .file-btn {
            max-width: 200px;
        }
    }

    .custom-quill-editor {
        .ql-snow {
            border: 0;
        }

        .ql-toolbar {
            background-color: var(--bs-white);
        }

        .ql-container {
            overflow: auto;
            padding: 7px 0 10px;

            .ql-editor {
                font-size: 14px;
                padding: 5px 8px;

                &.ql-blank::before {
                    color: var(--bs-secondary);
                    font-size: 15px;
                    font-style: normal;
                }
            }
        }
    }

    .submit-msg-wrapper {
        background-color: #b0b1b7;

        input {
            background-color: transparent;
        }
        button {
            background-color: #086efd;
        }
    }
}

.info-cards-wrapper {
    .info-card1 {
        background-color: rgba var(--bs-primary), 0.75;
    }
}

// Add new issue modal css
.addNewIssueModal {
    .form-label {
        color: $meeting-color1;
    }

    .custom-input {
        border-radius: .375rem;
        min-height: 46px;
    }

    // select:required:invalid {
    //     color: $meeting-color1 !important;
    // }

    .custom-quill-editor {
        .ql-toolbar {
            .ql-picker {
                color: #666766;
                /* Replace with your desired color */
            }

            .ql-stroke {
                stroke: #666766;
                /* Replace with your desired color */
            }

            .ql-fill {
                fill: #666766;
                /* Replace with your desired color */
            }
        }

        // min-height: 200px;
        .ql-snow {
            border: 0;
        }

        .ql-toolbar {
            background-color: #f4f5f5;
            margin: 5px 10px;
        }

        .ql-container {
            color: #000;
            font-size: 16px;
            min-height: 100px;
            max-height: 200px;
            overflow: auto;

            .ql-blank::before {
                color: $meeting-color1;
                font-size: 14px;
                font-style: normal;
            }
        }
    }

    .attachFile-wrapper {
        background-color: #cccccc;
    }
}


// Reopen issue modal css
.reopenIssueModal {
    max-width: 600px;
}
.description-wrapper img{
    max-width: 100%;
}
.loader-wrapper .loader{
    position: relative;
}
.loader-wrapper{
    position: fixed;
    padding-top: 50vh;
    background: #3339;
}
.loader-wrapper .loader-title {
    color: #f2f0f5;
    font-size: 0.875rem;
    background-color: transparent;
    display: inline-block;
    padding: 5px 1rem;
    text-shadow: 2px 1px 5px #1c13ab;
}